import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import classNames from 'classnames';

import Hero from '@/components/Hero';

const Service = ({ img, tags }) => {
  return (
    <Hero
      title={
        <div
          className={classNames(
            'w-auto inline-block p-4 2xl:px-12 2xl:py-6 bg-green-900 font-heading text-lg 2xl:text-3xl text-mainDark rounded-full cursor-pointer hover:shadow-csm hover:-translate-y-[3%] transition-all duration-50',
            tags.bg,
          )}
        >
          {tags.name}
        </div>
      }
      subTitle={<div>{tags.content}</div>}
      blobFirstColor={tags.blobFirstColor}
      blobSecondColor={tags.blobSecondColor}
      image={{
        image: getImage(img),
        alt: 'search icon',
      }}
    >
      <div className="flex flex-wrap gap-3 text-base lg:text-xl">
        {tags?.tags?.map((tagName, index) => (
          <div
            key={index}
            className={classNames(
              'mt-6 px-2 py-1 rounded-md font-light text-mainDark',
              tags.bg,
            )}
          >
            {tagName}
          </div>
        ))}
      </div>
    </Hero>
  );
};

export default Service;
