import React from 'react';
import LinkItem from '../LinkItem';

const ServiceLinkItem = ({ to, title }) => (
  <LinkItem
    className=" mt-9 xl:float-right lg:float-left font-heading text-xl"
    title={title}
    to={to}
  />
);

export default ServiceLinkItem;
